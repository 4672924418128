

































































































































import * as api from '@/api/projectDetail';
import { accAdd } from '@/utils';

interface resData {
  [key: string]: any;
}
const dialogColumns = [
  {
    title: '项目编码',
    dataIndex: 'fxCode',
    key: 'bdCode',
    scopedSlots: { customRender: 'bdCode' },
    align: 'center',
    width: 150,
  },
  {
    title: '项目名称',
    dataIndex: 'fxName',
    key: 'bdName',
    align: 'center',
    width: 200,
  },
  {
    title: '项目特征',
    dataIndex: 'projectAttr',
    key: 'projectAttr',
    scopedSlots: { customRender: 'projectAttr' },
    align: 'center',
    width: 200,
  },
  {
    title: '计量单位',
    dataIndex: 'unit',
    key: 'unit',
    scopedSlots: { customRender: 'unit' },
    align: 'center',
  },
  {
    title: '综合单价(元)',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#B0B0B0' } };
    },
  },
  {
    title: '人工费(元)',
    dataIndex: 'totalRgf',
    key: 'totalRgf',
    scopedSlots: { customRender: 'totalRgf' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#B0B0B0' } };
    },
  },
  {
    title: '材料费(元)',
    dataIndex: 'totalClf',
    key: 'totalClf',
    scopedSlots: { customRender: 'totalClf' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#B0B0B0' } };
    },
  },
  {
    title: '机械费(元)',
    dataIndex: 'totalJxf',
    key: 'totalJxf',
    scopedSlots: { customRender: 'totalJxf' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#B0B0B0' } };
    },
  },
  {
    title: '管理费及利润(元)',
    dataIndex: 'totalGlf',
    key: 'totalGlf',
    scopedSlots: { customRender: 'totalGlf' },
    align: 'center',
    customCell: () => {
      return { style: { color: '#B0B0B0' } };
    },
  },
  {
    title: '操作',
    dataIndex: 'options',
    key: 'options',
    scopedSlots: { customRender: 'options' },
    align: 'center',
    customCell: (record) => {
      if (record.kind === '03') {
        return {
          style: { display: record.rowSpan === 0 ? 'none' : '' },
          attrs: {
            rowSpan: record.rowSpan,
          },
        };
      } else if (record.kind === '02') {
        return {
          attrs: {
            rowSpan: 1,
          },
        };
      }
    },
  },
];
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { setTableHeaderWidth } from '@/utils';
import { resetQdColor } from '@/api/projectDetail';

export default Vue.extend({
  name: 'index',
  props: {
    visible: {
      type: Boolean,
    },
    record: {
      type: Object,
    },
    type: {
      type: Number,
      default: 1, // 1 分部分项 2 单价措施
    },
  },
  data() {
    return {
      dialogColumns,
      dialogData: [] as Array<string>,
      targetBillId: '',
      btnLoading: false, // 替换按钮loading
      active: 1, // 高低价推荐选中值
      minPrice: '',
      maxPrice: '',
      visibleListing: false, // 是否展示重复清单项替换页面
      isEdit: false, // 综合单价输入框是否可填写
      componentHeight: 0,
      tableHeadHeight: 0,
    };
  },
  computed: {
    ...mapGetters([
      'currentTreeInfo',
      'readOnly',
      'projectSequenceNbr',
      'isQuotaLibraryIdentify',
      'projectBidSequenceNbr',
      'listGenerating',
    ]),
    dialogScroll() {
      const num: number = this.componentHeight - this.tableHeadHeight;
      return { y: num };
    },
    componentsStyle(): { height: string } {
      return {
        height: this.componentHeight + 'px',
      };
    },
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.unitDeList(
          this.record.fxCode,
          this.record.unit,
          this.record.projectAttr
        );
        this.targetBillId = this.record.sequenceNbr;
        this.$nextTick(() => {
          this.setComponentHeight();
        });
        console.log('index.vue|283====>', this.record);
        window.addEventListener('resize', this.getTableHeaderWidth);
      } else {
        window.removeEventListener('resize', this.getTableHeaderWidth, false);
      }
    },
  },
  methods: {
    accAdd,
    ...mapMutations(['SET_IS_THERE_REPLACE']),
    hide() {
      this.$emit('update:visible', false);
      if (this.record.highlightColor === 1) {
        this.$emit('updateColorStatus', this.record);
      }
    },
    /**
     * type
     * 1 未组价 2 特殊清单项 3 组价推荐
     */
    goCostList() {
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
      const query = this.$route.query;
      if (!query) return;
      Object.assign(query, {
        type: 3,
        projectBidSequenceNbr: this.projectBidSequenceNbr,
        qdSequenceNbr: this.record.sequenceNbr,
        qdType: this.type === 1 ? 'fbfx' : 'djcs',
        biddingType: this.record.biddingType, // 招投标类型 0招标 1投标
      });
      sessionStorage.setItem('ysf_currentProjectQuery', JSON.stringify(query));
      this.$router.push('/ysf/costQuotaLibrary/quotaAdjustment');
    },
    getContainer() {
      return document.getElementById('ysf_body');
    },
    setComponentHeight() {
      this.$nextTick(() => {
        const tenderHeaderEl = this.$refs.headerInfo as HTMLDivElement;
        const footerEl = this.$refs.footerInfo as HTMLDivElement;
        const tenderHeaderHeight = tenderHeaderEl.clientHeight;
        const footerHeight = footerEl?.clientHeight || 0;
        console.log('2222222hhhhhjkkkkkkkggggfddddeeertgg', footerEl);
        this.componentHeight =
          window.innerHeight - tenderHeaderHeight - 56 - footerHeight; // 总投标的高度-头部标题高度 - marginTop
        const tableEl = document.querySelector(
          '.ant-table-thead'
        ) as HTMLElement;

        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        console.log(
          '111111111',
          window.innerHeight,
          tenderHeaderHeight,
          footerHeight,
          this.componentHeight
        );
        this.tableHeadHeight = tableHeadHeight;
      });
    },
    // 综合单价补录点击事件
    priceRecording() {
      this.$message.warning('该功能建设中~');
    },
    rowClassName(record: any) {
      let className = 'parent-show';
      if (record.kind === '03') className = 'child-show';
      return className;
    },
    changeSort(value) {
      this.active = value;
      if (!this.btnLoading) {
        this.unitDeList(
          this.record.fxCode,
          this.record.unit,
          this.record.projectAttr
        );
      }
    },
    checkChange(e) {
      console.log('e', e);
      this.isEdit = e.target.checked;
      if (!this.isEdit) {
        this.minPrice = '';
        this.maxPrice = '';
        this.unitDeList(
          this.record.fxCode,
          this.record.unit,
          this.record.projectAttr
        );
      }
    },
    goListing() {
      this.$emit('update:visible', false);
      this.visibleListing = true;
    },
    unitDeList(fxCode: string, unit: string, projectAttr: string) {
      this.btnLoading = true;
      let apiData = {
        fxCode: fxCode,
        unit: unit,
        projectAttr: projectAttr,
        zbId: this.projectSequenceNbr,
        dingEGroupId: this.record.dingEGroupId,
      };
      api
        .unitDeList(apiData)
        .then((res: resData) => {
          this.btnLoading = false;
          if (res.code) {
            let tempArray: Array<string> = [];
            res.result.map((item: any) => {
              tempArray.push(item.qdDTO);
              item.deDTO.forEach((child: any, index: number) => {
                if (index === 0) {
                  child.rowSpan = item.deDTO.length;
                } else {
                  child.rowSpan = 0;
                }
                child.qdSequenceNbr = item.qdDTO.sequenceNbr;
                tempArray.push(child);
              });
            });
            tempArray.forEach((item: any, index: number) => {
              item.index = index;
            });
            this.dialogData = tempArray;
            this.getTableHeaderWidth();
            if (this.record.highlightColor === 1) {
              this.resetQdColor();
            }
          } else {
            this.$message.warning('暂无组价推荐');
            this.dialogData = [];
          }
        })
        .catch((error) => {
          this.btnLoading = false;
        });
    },
    projectReplace(index: number) {
      if (this.listGenerating) {
        this.$message.warning('项目工程量投标清单正在生成中');
        return;
      }
      if (this.type === 1) {
        this.projectViewReplaceDe(index);
      } else {
        this.projectViewReplaceMeasureDe(index);
      }
    },
    // 招投标分部分项替换接口
    projectViewReplaceDe(index: number) {
      this.btnLoading = true;
      let apiData = {
        // unitId: this.currentTreeInfo?.bidSequenceNbr,
        targetBillId: this.targetBillId, // 被替换的清单id
        dingEGroupId: this.dialogData[index - 1]['dingEGroupId'], // 被替换的清单dingEGroupId
        extend2: this.dialogData[index - 1]['extend2'], // 被替换的清单extend2
        // selectBillId:
        //   this.dialogData[index]['parentId'] || this.dialogData[index]['id'], // 被选择的清单id
      };
      api
        .coccMtdsResource_1_project_repeatFbDe(apiData)
        .then((res) => {
          this.btnLoading = false;
          if (res.result) {
            this.$message.success('替换成功');
            this.SET_IS_THERE_REPLACE(true);
            if (this.record.biddingType === 0) {
              this.hide();
            } else {
              this.hide();
              this.$emit('cancelReplace', this.record);
            }
          } else {
            this.$message.error('替换失败');
          }
        })
        .catch((error) => {
          this.btnLoading = false;
        });
    },
    // 招投标单价措施替换接口
    projectViewReplaceMeasureDe(index: number) {
      this.btnLoading = true;
      let apiData = {
        // unitId: this.currentTreeInfo?.bidSequenceNbr,
        targetBillId: this.targetBillId, // 被替换的清单id
        dingEGroupId: this.dialogData[index - 1]['dingEGroupId'], // 被替换的清单dingEGroupId
        extend2: this.dialogData[index - 1]['extend2'], // 被替换的清单extend2
        // selectBillId:
        //   this.dialogData[index]['parentId'] || this.dialogData[index]['id'], // 被选择的清单id
      };
      api
        .coccMtdsResource_1_project_repeatMeasureDe(apiData)
        .then((res) => {
          this.btnLoading = false;
          if (res.result) {
            this.$message.success('替换成功');
            this.SET_IS_THERE_REPLACE(true);
            if (this.record.biddingType === 0) {
              this.hide();
            } else {
              this.hide();
              this.$emit('cancelReplace', this.record);
            }
          } else {
            this.$message.error('替换失败');
          }
        })
        .catch((error) => {
          this.btnLoading = false;
        });
    },
    // 综合单价input框校验
    proving(type) {
      if (type === 1) {
        this.minPrice = (this.minPrice.match(/\d+(\.\d{0,2}|100)?/) || [''])[0];
      } else {
        this.maxPrice = (this.maxPrice.match(/\d+(\.\d{0,2}|100)?/) || [''])[0];
      }
    },
    replaceFinish(val) {
      this.$emit('updateReplace', val);
    },
    getTableHeaderWidth() {
      setTableHeaderWidth('.recommend-list');
    },
    /**
     * 重置(恢复)高亮颜色-清单
     */
    resetQdColor() {
      const isSameItem =
        this.record.extend2 !== '3' && Number(this.record.extend3) > 0;
      let apiData = {
        queryTable: this.type === 1 ? 'fbfx' : 'djcs',
        ifSameItem: isSameItem,
        tbConstructSequenceNbr: isSameItem ? this.projectBidSequenceNbr : null,
        equalItemSpecial: isSameItem ? this.record.equalItemSpecial : null,
      };
      resetQdColor(this.record.sequenceNbr, apiData).then((res) => {
        console.log('重置(恢复)高亮颜色', res);
      });
    },
  },
});
